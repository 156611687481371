"use strict";

var _dictree = _interopRequireDefault(require("dictree"));
var _underscore = require("underscore");
var _lodash = require("lodash");
var _controls = require("libs/utils/controls");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var OVERRIDE_METADATA_BIT_ID_PREFIX = 'bstg-metadata-bit-';
var OVERRIDE_METADATA_BIT_TYPE = 'metadata-bit';
var OVERRIDE_METADATA_BIT_PRIORITY = 'highest';
var OVERRIDE_METADATA_BIT_OWNER = 'private';
var OVERRIDE_METADATA_FIELDS_TO_OMIT = ['field', 'selectedChoice', 'toBeDeleted', 'isDeleted', 'addKind', 'dirty', 'type'];
var GENERATED_DEFAULT_VALUE_PLACEHOLDER = '{{bstg:random}}';
var vanillaMetadata = window.BSTG.services.metadata;
var cleanUpDict = function cleanUpDict(dict, type, newOverrideEncounteredFields) {
  delete dict[type]._dynamic_ext;
  (0, _lodash.forOwn)(dict[type], function (field, key) {
    if (field) {
      delete field._registrations;
      if (newOverrideEncounteredFields.includes(key)) {
        delete field._to_prune;
      }
      if ((0, _underscore.isObject)(field.kind_options)) {
        // never prune kind_options, this is not needed and will break stuff
        delete field.kind_options._to_prune;
      }
    }
  });
  return dict;
};
var MetadataService = /* @ngInject */function MetadataService($q, $filter, eventService, bulkUpdateService) {
  // # # # #
  // public
  // # # # #

  var metadataService = {
    FIELD_CATEGORY_CATCHALL: 'others',
    FIELD_CATEGORY_GENERAL: 'general',
    getFieldPlacement: _controls.getFieldPlacement,
    compileMetadata: vanillaMetadata.compileMetadata.bind(vanillaMetadata),
    fetchAllMetadata: vanillaMetadata.getMetadata.bind(vanillaMetadata),
    fetchOverrideMetadataBit: function fetchOverrideMetadataBit(event) {
      var fpType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
      var _id = "".concat(OVERRIDE_METADATA_BIT_ID_PREFIX).concat(fpType);
      return eventService.getEventDocById(event, _id).then(function (data) {
        return data.data;
      }).catch(function (err) {
        if (err.status === 404) {
          return {};
        }
        throw err;
      });
    },
    getOverrideBitFromDiff: function getOverrideBitFromDiff(eventId, type, metadataFieldList, existingMetadata, existingOverrideBit, reprTemplates) {
      if (!existingMetadata) {
        existingMetadata = {};
      }
      if (!existingOverrideBit) {
        existingOverrideBit = {};
      }
      if (!reprTemplates) {
        reprTemplates = null;
      }
      var _id = "".concat(OVERRIDE_METADATA_BIT_ID_PREFIX).concat(type);
      var baseOverrideBit = {
        _id: _id,
        fp_ext_id: _id,
        fp_bit_priority: OVERRIDE_METADATA_BIT_PRIORITY,
        fp_owner: OVERRIDE_METADATA_BIT_OWNER
      };
      if (!_.isObject(existingMetadata[type])) {
        return baseOverrideBit;
      }
      if (!reprTemplates) {
        var typeMetadata = existingMetadata[type];
        reprTemplates = {
          _representation: typeMetadata._representation,
          _representation_line2: typeMetadata._representation_line2,
          _representation_line3: typeMetadata._representation_line3,
          _representation_image: typeMetadata._representation_image
        };
      }
      var baseFullOverrideBit = _defineProperty({}, type, {});

      // convert all the local fields (where are changes are) to an object "bit" form
      // we'll diff this with the metadata we currently have in the db later.
      var newOverrideEncounteredFields = []; // for detecting whether to null out the value below
      var newFullOverrideBit = _.sortBy(metadataFieldList, 'order').filter(function (item) {
        return _.isObject(item) && !item.isDeleted;
      }).reduce(function (bit, item, idx) {
        if (item.toBeDeleted) {
          bit[type][item.field] = null;
          item.isDeleted = true;
        } else {
          // omit special fields (see OVERRIDE_METADATA_FIELDS_TO_OMIT)
          bit[type][item.field] = _.omit.apply(_, [item].concat(OVERRIDE_METADATA_FIELDS_TO_OMIT));
          // normalise ordering (order becomes list index)
          bit[type][item.field].order = idx;
        }
        newOverrideEncounteredFields.push(item.field);
        return bit;
      }, baseFullOverrideBit);

      // add nulls back into the bit manually. this avoids rogue prunes when values disappear on subsequent runs
      _.forEach(existingMetadata[type], function (descriptor, field) {
        if (descriptor !== null) {
          return;
        }
        if (!Array.from(newOverrideEncounteredFields).includes(field)) {
          // if the field is back in the list don't null it
          newFullOverrideBit[type][field] = null;
        }
      });

      // copy over the _representation field from the big metadata, it may have been edited
      var existingMetadataForType = existingMetadata[type];
      _.extend(newFullOverrideBit[type], reprTemplates);

      // don't save an empty hash that might make the bit-compiler blat everything
      if (_.isEmpty(newFullOverrideBit[type])) {
        delete newFullOverrideBit[type];
      }

      // changes = diff(override, metadata)
      var whitelistedFields = ['_representation', '_representation_line2', '_representation_line3', '_representation_image'];
      var fieldsChangesDict = _dictree.default.diff(newFullOverrideBit[type], existingMetadataForType);
      fieldsChangesDict = _.omit(fieldsChangesDict, function (val, key) {
        return key.startsWith('_') && !whitelistedFields.includes(key);
      });
      var fullChangesDict = {};
      fullChangesDict[type] = fieldsChangesDict;

      // the diffed object cannot be null or contain an empty object. this will break stuff!
      // putting something here will prevent a wipe of the metadata if everything ends up being the same between overrides when diffed!
      // unfortunately this is necessary to get around the crappiness of this situation
      if (_.isObject(fullChangesDict[type]) && _.isEmpty(fullChangesDict[type])) {
        fullChangesDict[type]._ignore_this = true;
      }

      // copy over essential stuff from base override bit (ids and types and such)
      angular.extend(fullChangesDict, baseOverrideBit);

      // ABORT with changes dict if no existing override (create one)
      if (_.isEmpty(existingOverrideBit)) {
        return cleanUpDict(fullChangesDict, type, newOverrideEncounteredFields);
      }
      var mergedChanges = angular.copy(_dictree.default.merge([existingOverrideBit, fullChangesDict]), existingOverrideBit);
      // ⚑
      // TODO: This method is currently NOT compatible with dynamic extensions, and will simply ignore them.
      return cleanUpDict(mergedChanges, type, newOverrideEncounteredFields);
    },
    updateOverrideMetadataBit: function updateOverrideMetadataBit(eventId, eventNode, metadataDictOrList) {
      var fpType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'default';
      var _id = "".concat(OVERRIDE_METADATA_BIT_ID_PREFIX).concat(fpType);
      var baseMetadataDict = {
        _id: _id,
        fp_ext_id: _id,
        fp_bit_priority: OVERRIDE_METADATA_BIT_PRIORITY,
        fp_owner: OVERRIDE_METADATA_BIT_OWNER
      };
      var metadataDict = !_.isArray(metadataDictOrList) ? _.extend(_.clone(metadataDictOrList), baseMetadataDict) : metadataDictOrList.reduce(function (dict, item) {
        if (!dict[fpType]) {
          dict[fpType] = {};
        }
        dict[fpType][item.field] = _.omit(item, 'field');
        return dict;
      }, baseMetadataDict);

      // save the updated metadata doc, keeping its existing db id
      return bulkUpdateService.createDocs(eventId, eventNode, OVERRIDE_METADATA_BIT_TYPE, metadataDict, {
        keepId: true
      }).then(function () {
        return metadataService.fetchAllMetadata(eventId, {
          eventNode: event.node,
          forceUpdate: true
        });
      });
    },
    saveMetadataFieldsToOverride: function saveMetadataFieldsToOverride(eventId, eventNode, fpType, metadataFieldList, reprTemplates, currentMetadata) {
      var event = {
        id: eventId,
        _id: eventId,
        node: eventNode
      };
      return $q.all([metadataService.fetchAllMetadata(eventId, {
        eventNode: event.node,
        forceUpdate: true
      }), metadataService.fetchOverrideMetadataBit(event, fpType)]).then(function (results) {
        var _results = _slicedToArray(results, 2),
          allMetadata = _results[0],
          overrideBit = _results[1];
        // For now this check ensures that metadata editor won't allow conflict.
        // No change for the excel data import to this day as it's not a big source of conflict
        if (currentMetadata && currentMetadata._rev !== allMetadata._rev) {
          alert('Metadata have been updated while you were working on them. Please take note of your changes and refresh the page');
          throw new Error('Outdated metadata');
        }
        var finalOverrideBit = metadataService.getOverrideBitFromDiff(eventId, fpType, metadataFieldList, allMetadata, overrideBit, reprTemplates);

        // refresh the local metadata cache
        return metadataService.updateOverrideMetadataBit(eventId, event.node, finalOverrideBit, fpType).then(function () {
          return metadataService.fetchAllMetadata(eventId, {
            eventNode: event.node,
            forceUpdate: true
          });
        });
      });
    },
    getCachedMetadata: vanillaMetadata.getCachedMetadata.bind(vanillaMetadata),
    getCachedMetadataForType: vanillaMetadata.getCachedMetadataForType.bind(vanillaMetadata),
    getCachedMetadataForTypeAsArray: vanillaMetadata.getCachedMetadataForTypeAsArray.bind(vanillaMetadata),
    getCachedReprTemplateForType: function getCachedReprTemplateForType(eventId, fpType, mergeDynamicExtensions) {
      var mdHash = metadataService.getCachedMetadataForType(eventId, fpType, mergeDynamicExtensions);
      return mdHash._representation;
    },
    getCachedBulkEditFlagForType: function getCachedBulkEditFlagForType(eventId, fpType, mergeDynamicExtensions) {
      var mdHash = metadataService.getCachedMetadataForType(eventId, fpType, mergeDynamicExtensions);
      return mdHash._bulk_edit;
    },
    getCachedDuplicateFlagForType: function getCachedDuplicateFlagForType(eventId, fpType, mergeDynamicExtensions) {
      var mdHash = metadataService.getCachedMetadataForType(eventId, fpType, mergeDynamicExtensions);
      return mdHash._duplicate;
    },
    /**
     * This method will safely interpolate mustache values using an i18n filter.
     *
     * @param {String} label the label that can contain mustache markup.
     * @param {Object} context the object that contains mustache interpolation values.
     */
    translateObjects: function translateObjects(label, context, skipEscape) {
      var matches = (label || '').match(/{{([^}{}]*)}}/g);
      if (!matches) {
        return label;
      }

      // Search for relevand mustache keys
      var fields = matches.map(function (k) {
        return k.replace('{{', '').replace('}}', '');
      });
      if (skipEscape) {
        label = label.replace(/{{/g, '{{{').replace(/}}/g, '}}}')
        // {{{ are turned into {{{{ > fix this
        // this could be done above with a pretty unreadable regexp
        .replace(/{{{{/g, '{{{').replace(/}}}}/g, '}}}');
      }

      // We don't want to modify the original content/model for labels, thus
      // we create a clone and operate on this.
      var ctx = (0, _underscore.clone)(context);
      var _iterator = _createForOfIteratorHelper(fields),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var key = _step.value;
          // if the desired mustache key is an object, it means that it's a
          // key that we must translate, thus we give it to the i18n filter.
          if ((0, _underscore.isObject)(context[key])) {
            ctx[key] = $filter('i18nFieldValueToString')(ctx[key]);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return window.BSTG.utils.dom.compile(label, ctx);
    },
    getReprLabelForItem: function getReprLabelForItem(eventId, doc, skipEscape) {
      var fpType = (0, _lodash.get)(doc, 'fp_type');
      if (!fpType) {
        return null;
      }
      var tmpl = metadataService.getCachedReprTemplateForType(eventId, fpType);
      if (!tmpl) {
        return null;
      }
      return this.translateObjects(tmpl, doc, skipEscape);
    },
    getReprLabelForItemOrUseTitle: function getReprLabelForItemOrUseTitle(eventId, doc) {
      var label = metadataService.getReprLabelForItem(eventId, doc);
      return label || doc.name || doc.title || doc.subject || doc.fp_ext_id || doc._id;
    },
    getCategoryGroupedMetadataFields: function getCategoryGroupedMetadataFields() {
      var metadataFieldsList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var event = eventService.getCurrentEvent();
      var ORDER_BOTTOM = 'x';
      var CATEGORY_OTHER = metadataService.FIELD_CATEGORY_CATCHALL;
      if (!_.isArray(metadataFieldsList) && _.isObject(metadataFieldsList)) {
        // fields object to list form
        metadataFieldsList = metadataService.convertMetadataHashToOrderedList(metadataFieldsList);
      }
      var categoryOrderMap = {}; // to keep track of the lowest order per category!

      return _.chain(metadataFieldsList).compact().sortBy('order').map(function (field, idx) {
        // make sure all fields have orders
        if (_.isNaN(parseFloat(field.order))) {
          field.order = idx;
        }
        var _order = categoryOrderMap[field.category];
        // lowest order presides (and becomes the category order)

        if (!_.isNumber(_order) || field.order < _order) {
          categoryOrderMap[field.category] = field.order;
        }
        var isNotEditable = field._editable === false;
        var hasCustomController = field.kind === 'custom' && field.kind_options && field.kind_options.controller;
        var isPrivateField = field.field.startsWith('fp_');
        field.readOnly = Boolean(isNotEditable || hasCustomController || isPrivateField);
        if (field.context) {
          var eventContext = event.is_template ? 'template' : 'workspace';
          if (!field.context.includes(eventContext)) {
            field.hidden = true;
          }
        }
        return field;
      }).groupBy(function (field) {
        var category = field.category || CATEGORY_OTHER;
        var _order = categoryOrderMap[category];
        var order = _.isNumber(_order) ? _order : ORDER_BOTTOM;
        return "".concat(order, ":").concat(category);
      }).pairs().sortBy(function (pair) {
        return pair[0];
      })
      // back to category names
      .map(function (pair) {
        return {
          category: pair[0].split(':')[1],
          fields: pair[1],
          hasRequiredFields: pair[1].some(function (field) {
            var _field$validations;
            return (_field$validations = field.validations) === null || _field$validations === void 0 ? void 0 : _field$validations.required;
          })
        };
      }).sortBy(function (category) {
        return category.fields[0].order;
      }).map(function (obj, idx, list) {
        obj.previous = list[idx - 1];
        obj.next = list[idx + 1];
        return obj;
      }).value();
    },
    convertMetadataHashToOrderedList: vanillaMetadata.convertMetadataHashToOrderedList.bind(vanillaMetadata),
    displaceInAppFieldsAsFieldKeys: function displaceInAppFieldsAsFieldKeys(mdHashOrArray) {
      var useInAppFieldKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var isArray = _.isArray(mdHashOrArray);
      var outHash = _.reduce(mdHashOrArray, function (outHash, fieldDescriptor, fieldKey) {
        if (!fieldDescriptor) {
          // ignore null fields
          return outHash;
        }
        var origKey = isArray ? fieldDescriptor.field : fieldKey;
        var newKey;
        if (!useInAppFieldKeys) {
          newKey = origKey;
        } else {
          newKey =
          // if in_app is a string use it, if it is only true infer the name
          _.isString(fieldDescriptor.in_app) ? fieldDescriptor.in_app : origKey;
        }
        outHash[newKey] = fieldDescriptor;
        if (isArray) {
          outHash[newKey].field = newKey;
        }
        return outHash;
      }, {});
      if (isArray) {
        return _.sortBy(_.values(outHash), 'order');
      } else {
        return outHash;
      }
    },
    // n.b. arrays only plz
    findFieldsWithFlag: vanillaMetadata.findFieldsWithFlag.bind(vanillaMetadata),
    // recursively replace placeholders in val, and return the modified object
    // for now only {{bstg:random}} is supported
    replacePlaceholdersInTree: function replacePlaceholdersInTree(val) {
      if (!val) {
        return val;
      }
      if (_.isString(val) && val.match(GENERATED_DEFAULT_VALUE_PLACEHOLDER)) {
        return val.replace(new RegExp(GENERATED_DEFAULT_VALUE_PLACEHOLDER, 'g'), bulkUpdateService.generateAutoGeneratedValueString());
      }

      // handle array and object recursively
      if (_.isObject(val)) {
        _.each(val, function (value, key) {
          return val[key] = metadataService.replacePlaceholdersInTree(value);
        });
      }
      return val;
    },
    /**
     * Returns a document of type fp_type where all values are the default values.
     *
     * @param {Object} fields
     * @return {Object}
     */
    extractDefaults: function extractDefaults(fields) {
      return _.chain(fields).pairs()
      // retrieve default value for fields
      .map(function (regEntry) {
        var entry = regEntry[1];
        if (!entry) {
          return;
        }
        var field = entry.field || regEntry[0];

        // simple case, use field.default if not nested object
        if (entry.kind !== 'nested-object' || !_.isEmpty(entry.default) || !entry.kind_options || !entry.kind_options.fields) {
          // return a copy of the default value, so it can be modified
          return [field, _.clone(entry.default)];
        }

        // otherwise, extract default from kind_options fields
        var optionsFields = (entry.kind_options || {}).fields;
        if (optionsFields) {
          return [field, metadataService.extractDefaults(optionsFields)];
        }
      })
      // remove fields without defaults and undefined defaults
      .compact().filter(function (regEntry) {
        return !_.isUndefined(regEntry[1]);
      })
      // replace {{placeholders}} in default value
      .map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          field = _ref2[0],
          defaultValue = _ref2[1];
        return [field, metadataService.replacePlaceholdersInTree(defaultValue)];
      })
      // build final default object
      .object().value();
    },
    filterDuplicateTargetsExceptionsFields: function filterDuplicateTargetsExceptionsFields(fieldsList) {
      return window.BSTG.services.metadata.filterDuplicateTargetsExceptionsFields(fieldsList);
    },
    filterNullKindOptionsValues: function filterNullKindOptionsValues() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _.chain(values).pairs().filter(function (pair) {
        return pair[1] !== null;
      }).object().value();
    },
    getKindOptions: function getKindOptions() {
      return window.BSTG.services.metadata.ALL_KINDS;
    },
    /**
     * Extract values from a kind_otions and return a sorted select-ready structure
     * @param {Object} kindOptions a metadata kind_options structure
     * @return {Array}
     */
    getChoiceOptionsOrderedList: function getChoiceOptionsOrderedList() {
      var kindOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var kindValues = kindOptions.values || {};
      var kindValuesOrder = kindOptions.values_order || {};
      var filteredValues = metadataService.filterNullKindOptionsValues(kindValues);
      var maxOrder = 0;
      return _.chain(filteredValues).keys()
      // first assign known orders
      .map(function (key) {
        var order = kindValuesOrder[key];
        var option = {
          label: filteredValues[key],
          value: key
        };
        if (order || order === 0) {
          option.order = order;
          maxOrder = Math.max(maxOrder, order + 1);
        }
        return option;
      })
      // then only add missing order based on index
      .map(function (option, index) {
        if (option.order === undefined) {
          option.order = maxOrder + index;
        }
        return option;
      }).sortBy('order').value();
    },
    getTypeRepresentations: function getTypeRepresentations(metadataTypes, metadata) {
      var humanizeFn = $filter('humanize');
      var uppercaseFn = $filter('uppercaseFirstCharOfAllWords');
      return metadataTypes.map(function (key) {
        var rep = (metadata[key] || {})._type_representation;
        if (!_.isString(rep)) {
          rep = uppercaseFn(humanizeFn(key));
        }
        return {
          id: key,
          text: rep
        };
      })
      // only show types with a text
      .filter(function (_ref3) {
        var text = _ref3.text;
        return text;
      });
    }
  };
  return metadataService;
};
MetadataService.$inject = ["$q", "$filter", "eventService", "bulkUpdateService"];
MetadataService.$inject = ["$q", "$filter", "eventService", "bulkUpdateService"];
angular.module('backstage.services').factory('metadataService', MetadataService);